//------------------------------------//
// Magesparrow - Theme Settings       //
//-----------------------------------//
// Do not remove this.
@import "../../../web/css/magesparrow-theme";
//-----------------------------------//

//------------------------------------//
// Magesparrow - Blank Theme styles   //
//------------------------------------//
// Do not remove this.
@import "magesparrow-blank/Magento_Checkout/web/css/module";
//------------------------------------//

/* fix payment method issues when company credit is enabled */
body.checkout-index-index .payment-method {
    .available-credit {
        display: flex;
        width: 100%;
    }

    .payment-method-content {
        width: 100%;
    }

    .payment-method-title {
        flex-wrap: wrap;
    }
    .amcompany-paycard-block.-balance-card {
        background-color: $white;
        border-color: $white;
    }
}

/* override label targetted globally - fixes float label issues */
body .payment-method .payment-method-title .checkout-billing-address .label>span {
    padding-left: 0;
}
.payment-method .payment-method-title .label {
    font-weight: normal;
    text-transform: uppercase;
}

.payment-method .payment-method-title .available-credit .label {
    text-transform: none;
}

.payment-method .payment-method-title .available-credit {
    margin-bottom: 1rem;

    .radio {
        margin-bottom: 0;
    }
}

body .payment-method .payment-method-title .label.fl-label {
    font-size: 12px;
    position: absolute;
    font-weight: 800;
    margin-bottom: .5rem;
}

body .form-billing-address-form .choice.field .label {
    font-size: 1rem;
    text-transform: none;
    font-weight: normal;
}

body .table-checkout-shipping-method tbody tr td.col-method {
    .field {
        margin-bottom: 0;
    }

    input[type="radio"] {
        margin-top: 0;
        margin-bottom: 0;
        height: 26px;
    }
}

body .checkout-container {
    .form.methods-shipping select,
    .form.methods-shipping input {
        border-width: $input-border-width;
    }
}

.opc-block-summary .block.items-in-cart .title {
    color: $black;
}

body .checkout-container>.opc-sidebar {
    @include respond-to-up($tweakpoint-nav-toggle) {
        top: 40px;
    }
}

body .checkout-container .opc-block-summary {
    padding-top: 0;
}

body .checkout-container .opc-estimated-wrapper {
    top: 44px;
}

body .opc-progress-bar-item._active span {
    color: map-get($theme-colors, 'primary');
}

body .payment-method .payment-method-title .radio {
    margin-bottom: 0;
}

body .payment-method .payment-method-title .label {
    text-transform: none;
    font-weight: bold;
}

body .price-excluding-tax:before,
body .price-including-tax:before {
    top: 0;
}

body .payment-option._collapsible {
    .action-toggle {
        @include ms-icon('plus');
        position: relative;

        &:before {
            position: absolute;
            top: 8px;
            right: 0;
        }
    }
    &._active {
        .action-toggle:before {
            content: map-get($ms-icons, 'minus');
        }
    }

    .payment-option-content {
        padding: 1rem 0 0;
    }

    .actions-toolbar {
        justify-content: center;
    }
}


#cc_c2a.c2a_mode1 {
    z-index: 9999;
}

body .opc-wrapper .step-title,
body .opc-block-summary > .title {
    background: $black;
    border: 2px solid $black;
    color: $white;
}
body .shipping-address-items .shipping-address-item {
    border: 2px solid #ccc;
    background-color: #efefef;
    color: $black;
    width: 100%;

    &:before {
        border: 1px solid $black;
    }

    @include respond-to-up(smm) {
        width: 256px;
    }
}

body .opc-wrapper .opc>li {
    @include respond-to-down(smm) {
        padding-left: 0;
        padding-right: 0;
    }
}
body .shipping-address-items .shipping-address-item.selected-item {
    border: 2px solid #ccc;
    background-color: #efefef;
    color:$black;
    &:before {
        border: 1px solid $black;
    }
}
body .opc-block-summary .block.items-in-cart {
    //border: 2px solid #ccc;
    background-color: #efefef;
}
body .opc-block-summary .block.items-in-cart.active>.minicart-items {
    background-color: white;
}

#co-payment-form hr {
    display: none;
}

body .payment-method._active {
    background-color: #efefef;
}

body .payment-option._collapsible {
    background: #efefef;
    border: 2px solid #ccc;
    float: none;
    width: 100%;
}

body .opc-block-summary .table-totals .grand.totals {
    border-width: 2px;
    border-color: #ccc;
}

body.checkout-cart-index .cart-summary .table .grand.totals {
    border-top: 2px solid #ccc;
    font-size: 1rem;

    th {
        color: inherit;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    td.amount .price {
        font-size: 1.32rem;
    }
}
body.checkout-cart-index .cart-summary .cart-totals th {
    padding-bottom: 1rem;
}

body.checkout-cart-index .cart-summary .table .sub.totals {
    font-size: 1rem;
    font-weight: bold;

    th {
        font-weight: bold;
        color: inherit;
    }

    td .price {
        font-size: 1.1rem;
    }
}

body .item-options dd.values,
body .cart-container-main .cart.table .item-options dd,
body .cart-container-main .cart.table .item-options dt {
    display: inline-block;
    margin: 0;
    font-size: .875rem;
    line-height: normal;
}

body .opc-block-summary .minicart-items ol .options .content,
body .opc-block-summary .minicart-items ol .options .toggle {
    padding-left: 0;
    padding-right: 0;
}

/* basket page summary box background */
body .cart-container .cart-container-sidebar .cart-summary {
    background: #efefef;
    border: 2px solid #ccc;
}

body .cart-container-main .cart.table .cart.item:not(.message) {
    border: 2px solid #ccc;
}

body .opc-estimated-wrapper .estimated-block,
body .opc-estimated-wrapper .minicart-wrapper {
    @include respond-to-down($tweakpoint-nav-toggle) {
        width: unset;
        color: $black;
    }
}

body .opc-estimated-wrapper .minicart-wrapper .showcart.action,
body .opc-estimated-wrapper .estimated-price {
    color: $black;
}

body .amcompany-paycard-block {
    margin-top: 20px;
}

body.checkout-index-index {
    .ntrn__toggle {
        display: none;
    }

    &.page-layout-1column .page-main {
        margin-top: 0;
    }
}

body .checkout-container .modal-custom.opc-sidebar .opc-block-summary {
    @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        padding-top: 20px;

        >.title {
            display: none;
        }
    }
}

body .table-checkout-shipping-method {
    td {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    tbody tr td.col-method input[type=radio] {
        @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
            margin: 0;
        }
    }
}

body .cart-container {
    margin-bottom: 3rem;
}

/* basket page rental system form element spacing */
.cart-container-sidebar .form.form-rental-period .fieldset {
    margin-bottom: 1rem;
}

body .cart-container .cart-container-sidebar {
    @include respond-to-up($tweakpoint-nav-toggle) {
        width: calc(100% - 570px - 18px);
        max-width: 450px;
    }
}

/* hide print button from checkout success page LGH-488 */
body .checkout-success .actions-toolbar {
    margin-top: 0;
    margin-bottom: 0;

    .primary {
       margin-bottom: 0;
    }
    .secondary .action.print {
        display: none;
    }
}

body .checkout-success-block {
    margin-bottom: 1.5rem;
}

/* remove the billing address tick box and address on billing step checkout LGH-497 */
body .checkout-payment-method .checkout-billing-address {
    display: none;
}
