// Placeholders ONLY
%button-cheer {
    font-family: $font-family-condensed;
    font-weight: $font-weight-bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    background: $button-cheer-bg;
    color: $button-cheer-color;
    text-transform: $button-cheer-text-transform;
    padding: $button-cheer-padding-y $button-cheer-padding-x;

    @include hover-focus {
        color: $button-cheer-hover-color;
        background: $button-cheer-hover-bg;
    }
}

%button-cheer-with-icon {
    @include ms-icon('arrow-right', 20px, after);
    font-family: $font-family-condensed;
    font-weight: $font-weight-bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    background: $button-cheer-bg;
    color: $button-cheer-color;
    text-transform: $button-cheer-text-transform;
    padding: $button-cheer-padding-y $button-cheer-padding-x;

    @include hover-focus {
        color: $button-cheer-hover-color;
        background: $button-cheer-hover-bg;
    }

    &:after {
        display: inline-block;
        margin-top: 2px;
        margin-left: 1rem;
        vertical-align: top;
        font-weight: bold;
        font-size: inherit;
    }
}

%button-cheer-inverted {
    font-family: $font-family-condensed;
    background: $button-cheer-inverted-bg;
    color: $button-cheer-inverted-color;
    text-transform: $button-cheer-inverted-text-transform;
    font-weight: $font-weight-bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    padding: $button-cheer-inverted-padding-y $button-cheer-inverted-padding-x;

    @include hover-focus {
        color: $button-cheer-inverted-hover-color;
        background: $button-cheer-inverted-hover-bg;
    }
}

%button-whisper {
    @include ms-icon($button-whisper-icon, 20px, after);
    background: $button-whisper-bg;
    color: $button-whisper-color;
    text-transform: $button-whisper-text-transform;
    font-weight: $font-weight-bold;
    padding: $button-whisper-padding-y $button-whisper-padding-x;
    letter-spacing: $button-whisper-letter-spacing;
    position: relative;
    display: inline-block;

    @include hover-focus {
        color: $button-whisper-hover-color;
        background: $button-whisper-hover-bg;
    }

    &:after {
        margin-top: 0;
        margin-left: $button-whisper-icon-spacing-x;
        font-weight: bold;
        display: inline-block;
        vertical-align: middle;
        font-size: inherit;
    }
}
