@charset "UTF-8";
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
.cart-container-main .cart.table .action.action-delete > span, .checkout-cart-index .page-title-wrapper, .summary.title, .checkout-index-index .page-title-wrapper, .opc-block-summary .minicart-items ol .options .content .subtitle, .shipping-information .action-edit > span, .form.payments > .fieldset > legend {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.gift-options-cart-item .actions-toolbar > .secondary, .block.giftcard .title > span,
.block.giftcard .title > strong,
.gift-item-block .title > span,
.gift-item-block .title > strong,
.block.discount .title > span,
.block.discount .title > strong, .totals-sticky-item__total, .opc-progress-bar, .opc-progress-bar-item._complete span:before, .shipping-information .shipping-information-title, .shipping-address-items, .payment-method .payment-method-title, .payment-method .payment-method-title .label, .payment-method .payment-method-note, .checkout-billing-address .actions-toolbar .primary {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
}

.gift-item-block .actions-toolbar .secondary, .totals-sticky-item, .opc-progress-bar-item, .opc-progress-bar-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
}

/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
#checkout-step-shipping .action-show-popup {
  background: #03a84e;
  color: #fff;
  text-transform: normal;
  font-weight: 800;
  padding: 14px 36px;
}

#checkout-step-shipping .action-show-popup:focus, #checkout-step-shipping .action-show-popup:hover {
  color: #fff;
  background: #012b14;
}

.shipping-address-items .action-select-shipping-item {
  background: #b0aa00;
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
  padding: 14px 36px;
}

.shipping-address-items .action-select-shipping-item:focus, .shipping-address-items .action-select-shipping-item:hover {
  color: #fff;
  background: #7d7900;
}

.payment-option._collapsible .actions-toolbar .action {
  background: transparent;
  color: #000;
  text-transform: uppercase;
  font-weight: 800;
  padding: 14px 15px;
  border: 2px solid #000;
}

.payment-option._collapsible .actions-toolbar .action:focus, .payment-option._collapsible .actions-toolbar .action:hover {
  color: #97999C;
  border-color: #97999C;
  background: transparent;
}

.cart-container-main .cart.table .action.action-delete {
  width: 16px;
  height: 16px;
  padding: 1rem;
}

.cart-container-main .cart.table .action.action-delete:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.cart-container-main .cart.table .action.action-delete:before {
  width: 16px;
  height: 16px;
  font-size: 16px;
}

.cart-container-main .cart.table .action.action-delete:focus {
  outline: none;
}

.checkout-cart-index table caption, .gift-options-cart-item .gift-options-title, .block.giftcard .title,
.gift-item-block .title,
.block.discount .title {
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  line-height: 1;
}

.checkout-cart-index table caption {
  margin-bottom: 0.5rem;
}

.checkout-cart-index table caption {
  font-family: "Univers Blackest", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 1;
  letter-spacing: -0.02em;
}

.checkout-cart-index table caption {
  font-size: 13px;
}

@media screen and (min-width: 0px) {
  .checkout-cart-index table caption {
    font-size: calc(13px + 12 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .checkout-cart-index table caption {
    font-size: 25px;
  }
}

.shipping-address-items .shipping-address-item, .payment-method {
  transition: background-color 0.225s cubic-bezier(0, 0, 0.2, 1);
  will-change: background-color;
  backface-visibility: hidden;
}

.shipping-address-items .shipping-address-item:before, .shipping-address-items .action-select-shipping-item, .payment-method .payment-method-content, .payment-method .payment-method-content > * {
  transition: transform 0.375s cubic-bezier(0, 0, 0.2, 1), opacity 0.375s cubic-bezier(0, 0, 0.2, 1), visibility 0.375s cubic-bezier(0, 0, 0.2, 1);
  will-change: transform;
  backface-visibility: hidden;
}

.gift-options-cart-item .gift-content, .block.giftcard .content,
.gift-item-block .content,
.block.discount .content {
  transition: max-height 0.375s cubic-bezier(0, 0, 0.2, 1), visibility 0.375s cubic-bezier(0, 0, 0.2, 1);
  will-change: max-height;
  backface-visibility: hidden;
}

.shipping-address-items .action-select-shipping-item {
  font-family: "Univers Condensed Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  background: #b0aa00;
  color: #fff;
  text-transform: uppercase;
  padding: 14px 36px;
}

.shipping-address-items .action-select-shipping-item:focus, .shipping-address-items .action-select-shipping-item:hover {
  color: #fff;
  background: #7d7900;
}

.cart-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .cart-container {
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 992px) {
  .cart-container {
    margin-bottom: 3rem;
  }
}

@media screen and (min-width: 1200px) {
  .cart-container {
    margin-bottom: 4rem;
  }
}

.cart-container .cart-container-main {
  width: 100%;
  background: #fff;
  padding: 1rem;
  max-width: 570px;
}

@media screen and (min-width: 992px) {
  .cart-container .cart-container-main {
    padding: 0;
    width: calc(100% - 388px);
  }
}

@media screen and (max-width: 430px) {
  .cart-container .cart-container-main {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.cart-container .cart-container-main .main.actions {
  display: none;
}

.cart-container .cart-container-sidebar {
  width: 100%;
  max-width: 570px;
}

@media screen and (min-width: 992px) {
  .cart-container .cart-container-sidebar {
    width: 370px;
    margin-left: 18px;
    padding-top: 70px;
  }
}

.cart-container .cart-container-sidebar .cart-summary {
  position: relative;
  padding: 1rem 1.5rem;
  background: rgba(238, 238, 238, 0.4);
}

@media screen and (min-width: 992px) {
  .cart-container .cart-container-sidebar .cart-summary {
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    top: 1rem;
    z-index: 1;
  }
}

.cart-container .cart-container-sidebar .cart-summary .block.shipping {
  display: none;
}

.checkout-cart-index table caption {
  width: 100%;
  caption-side: top;
}

.checkout-cart-index .page-main {
  padding-top: 1rem;
}

.checkout-cart-index .messages.page .messages-fixed-wrapper {
  max-width: 958px;
}

.cart-container-main .cart.table {
  width: 100%;
}

.cart-container-main .cart.table caption {
  padding-top: 0;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid transparent;
  text-align: center;
}

@media screen and (min-width: 430px) {
  .cart-container-main .cart.table caption {
    text-align: left;
  }
}

.cart-container-main .cart.table thead th {
  display: none;
}

.cart-container-main .cart.table .item.message {
  padding: 0.25rem 1rem 0.25rem 0.25rem;
  max-width: 210px;
  font-size: 11px;
  margin-left: 1.5rem;
  background: #b70e0e;
  color: #fff;
  font-weight: bold;
}

@media screen and (max-width: 430px) {
  .cart-container-main .cart.table .item.message {
    position: absolute;
    top: auto;
    right: -17px;
    bottom: -34px;
  }
}

.cart-container-main .cart.table .item.message:before {
  content: "";
  padding: 0 0.5rem;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
}

.cart-container-main .cart.table .cart.item:not(.message) {
  position: relative;
  display: block;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  background: #fff;
  border: 2px solid #eee;
}

.cart-container-main .cart.table .cart.item:not(.message) .item-actions,
.cart-container-main .cart.table .cart.item:not(.message) .item-actions td {
  display: block;
  width: 100%;
}

.cart-container-main .cart.table .cart.item:not(.message).is-loading {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1089;
}

.cart-container-main .cart.table .cart.item:not(.message).is-loading:before {
  background-image: url("../../images/loaders/invert/ball-triangle.svg");
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  background-repeat: no-repeat;
  background-position: center center;
  content: '';
  z-index: 1090;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cart-container-main .cart.table .cart.item:not(.message).is-loading:before {
    background-image: url("../../images/loaders/invert/ball-triangle.gif");
  }
}

.cart-container-main .cart.table .product-item-photo {
  display: block;
  width: 60px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .cart-container-main .cart.table .product-item-photo {
    width: 100px;
  }
}

@media screen and (min-width: 992px) {
  .cart-container-main .cart.table .product-item-photo {
    width: 133px;
  }
}

.cart-container-main .cart.table .product-image-photo {
  max-width: 100%;
}

.cart-container-main .cart.table .item-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
}

.cart-container-main .cart.table .item-info > .col {
  border: 0 none;
}

.cart-container-main .cart.table .item-info > .col:first-child {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  margin-bottom: 0.5rem;
}

.cart-container-main .cart.table .item-info > .col:first-child .product-item-details {
  width: calc(100% - 90px);
}

@media screen and (min-width: 768px) {
  .cart-container-main .cart.table .item-info > .col:first-child .product-item-details {
    width: calc(100% - 130px);
  }
}

@media screen and (min-width: 992px) {
  .cart-container-main .cart.table .item-info > .col:first-child .product-item-details {
    width: calc(100% - 163px);
  }
}

.cart-container-main .cart.table .col.price,
.cart-container-main .cart.table .col.subtotal,
.cart-container-main .cart.table .col.qty {
  text-align: center;
  align-self: flex-start;
}

.cart-container-main .cart.table .col.price:before,
.cart-container-main .cart.table .col.subtotal:before,
.cart-container-main .cart.table .col.qty:before {
  display: block;
  color: #767676;
  font-size: 0.875rem;
  line-height: 2;
  content: attr(data-th);
}

.cart-container-main .cart.table .cart-price {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .cart-container-main .cart.table .action.action-cancel,
  .cart-container-main .cart.table .action.action-update {
    font-size: 0.75rem;
  }
}

.cart-container-main .cart.table .col.qty {
  max-width: 110px;
}

.cart-container-main .cart.table .col.qty .qty.field {
  margin-bottom: 0;
}

.cart-container-main .cart.table .col.qty .input-text {
  text-align: center;
}

.cart-container-main .cart.table .col.qty .input-text:focus {
  border-color: #d9d9d9;
}

.cart-container-main .cart.table .item-options,
.cart-container-main .cart.table .product-item-name {
  padding-left: 0.5rem;
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .cart-container-main .cart.table .item-options,
  .cart-container-main .cart.table .product-item-name {
    padding-left: 1.5rem;
  }
}

.cart-container-main .cart.table .product-item-name {
  display: block;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 768px) {
  .cart-container-main .cart.table .product-item-name {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 768px) {
  .cart-container-main .cart.table .price-excluding-tax .price,
  .cart-container-main .cart.table .price-including-tax .price {
    font-size: 1rem;
  }
}

.cart-container-main .cart.table .item-options dt {
  color: #767676;
  font-size: 0.875rem;
  font-weight: normal;
}

.cart-container-main .cart.table .item-options dd {
  font-weight: bold;
  font-size: 0.875rem;
}

@media screen and (min-width: 768px) {
  .cart-container-main .cart.table .item-options dd {
    font-size: 1rem;
  }
}

.cart-container-main .cart.table .actions-toolbar {
  margin: 0;
}

.cart-container-main .cart.table .action.towishlist, .cart-container-main .cart.table .action.action-edit {
  display: none;
}

.cart-container-main .cart.table .action.action-delete {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 2;
}

.cart-container-main .cart.table .action.action-delete:before {
  content: "";
}

.cart-container-main .cart.table.is-update-active .cart.item {
  opacity: 0.3;
  pointer-events: none;
}

.cart-container-main .cart.table.is-update-active .cart.item .price,
.cart-container-main .cart.table.is-update-active .cart.item .gift-options-cart-item {
  opacity: 0.3;
  pointer-events: none;
}

.cart-container-main .cart.table.is-update-active .cart.item.is-update-active {
  opacity: 1;
  pointer-events: auto;
}

.basket-item-update-panel {
  padding-top: 1rem;
  width: 100%;
  background: transparent;
  justify-content: space-around;
}

.basket-item-update-panel.is-active {
  animation: u-animation-slide-down-xsmall 0.375s cubic-bezier(0, 0, 0.2, 1);
  animation-delay: 0.05s;
  animation-fill-mode: both;
}

.basket-item-update-panel:not(.is-active) {
  display: none;
}

@media screen and (max-width: 768px) {
  .basket-item-update-panel {
    flex-direction: row;
  }
}

.basket-session-info {
  display: block;
  width: 100%;
  font-size: 1rem;
  text-transform: none;
  letter-spacing: normal;
  font-weight: normal;
  font-family: "Univers LT Std", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

@media screen and (min-width: 430px) {
  .basket-session-info {
    text-align: right;
  }
}

.cart-summary .cart-totals {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #eee;
}

.cart-summary .table-caption {
  color: #000;
  padding-top: 0;
  padding-bottom: 0;
}

.cart-summary .table {
  width: 100%;
}

.cart-summary .table td {
  padding: 0.25rem;
}

.cart-summary .table .amount {
  text-align: right;
}

.cart-summary .table .totals-tax,
.cart-summary .table .totals:not(.grand) {
  font-size: 0.875rem;
}

.cart-summary .table .totals-tax th,
.cart-summary .table .totals:not(.grand) th {
  color: #767676;
  font-weight: 400;
}

.cart-summary .table .totals-tax {
  border-bottom: 2px solid #eee;
}

.cart-summary .table .totals-tax th,
.cart-summary .table .totals-tax td {
  padding-bottom: 1rem;
}

.cart-summary .table .totals-tax + .grand.totals th,
.cart-summary .table .totals-tax + .grand.totals td {
  padding-top: 1rem;
}

@media screen and (min-width: 768px) {
  .cart-summary .table .grand.totals {
    font-size: 1.25rem;
  }
}

.cart-summary .table .grand.totals th {
  color: #767676;
}

.cart-summary-accepted-payments {
  display: block;
  margin-top: 2rem;
}

.cart-summary-accepted-payments p {
  color: #767676;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: bold;
}

.cart-summary-accepted-payments ul {
  padding-left: 0;
  list-style: none;
  text-align: center;
}

.cart-summary-accepted-payments ul > li {
  display: inline-block;
  vertical-align: middle;
  padding: 0.5rem;
}

.cart-summary-accepted-payments ul > li {
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.cart-summary-accepted-payments ul img {
  width: 44px;
  height: auto;
}

.cart-summary-additional-blocks {
  padding: 1rem;
  background: #fff;
  text-align: center;
  font-size: 0.875rem;
}

.cart-summary-additional-blocks ul {
  margin: 0;
}

.cart-summary-additional-blocks ul > li {
  padding-bottom: 0.5rem;
}

.cart-summary-additional-blocks ul > li:last-child {
  padding-bottom: 0;
}

.gift-options-cart-item {
  width: 100%;
}

.gift-options-cart-item .gift-options-title {
  color: #767676;
  margin-bottom: 0.5rem;
}

.gift-options-cart-item .action-gift {
  box-sizing: border-box;
  font-size: 0.875rem;
}

.gift-options-cart-item .action-gift:after {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.gift-options-cart-item .action-gift:after {
  transform-origin: 50% 50%;
  margin-left: 1rem;
}

.gift-options-cart-item .action-gift._active:after {
  content: "";
}

.gift-options-cart-item .gift-content {
  display: block;
  visibility: hidden;
  overflow: hidden;
  max-height: 0;
  transition-duration: 0.6s;
  transition-timing-function: ease-in-out;
}

.gift-options-cart-item .gift-content._active {
  visibility: visible;
  max-height: 650px;
}

.gift-options-cart-item .gift-options {
  padding: 1rem 1rem;
  background: #fff;
}

.gift-options-cart-item .actions-toolbar > .secondary {
  justify-content: space-between;
  flex-wrap: wrap;
}

.gift-options-cart-item .actions-toolbar > .secondary .secondary {
  flex: 0 0 auto;
  width: auto;
}

@media screen and (max-width: 430px) {
  .gift-options-cart-item .actions-toolbar > .secondary .action {
    margin-top: 1rem;
  }
}

.block.giftcard,
.gift-item-block,
.block.discount {
  margin-top: 1rem;
  width: 100%;
}

.block.giftcard .title,
.gift-item-block .title,
.block.discount .title {
  color: #767676;
  margin-bottom: 0;
}

.block.giftcard .title > span,
.block.giftcard .title > strong,
.gift-item-block .title > span,
.gift-item-block .title > strong,
.block.discount .title > span,
.block.discount .title > strong {
  position: relative;
  padding: 1rem;
  padding-right: 38px;
  justify-content: flex-start;
  font-size: 0.875rem;
  background: #fff;
  width: auto;
}

.block.giftcard .title > span:after,
.block.giftcard .title > strong:after,
.gift-item-block .title > span:after,
.gift-item-block .title > strong:after,
.block.discount .title > span:after,
.block.discount .title > strong:after {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.block.giftcard .title > span:after,
.block.giftcard .title > strong:after,
.gift-item-block .title > span:after,
.gift-item-block .title > strong:after,
.block.discount .title > span:after,
.block.discount .title > strong:after {
  position: absolute;
  right: 0;
  transform-origin: 50% 50%;
  margin-left: 1rem;
  margin-right: 1rem;
}

.block.giftcard .title[aria-expanded=true] > strong:after,
.gift-item-block .title[aria-expanded=true] > strong:after,
.block.discount .title[aria-expanded=true] > strong:after {
  content: "";
}

.block.giftcard .title:focus,
.gift-item-block .title:focus,
.block.discount .title:focus {
  outline: 0;
}

.block.giftcard .content,
.gift-item-block .content,
.block.discount .content {
  display: block !important;
  visibility: hidden;
  overflow: hidden;
  max-height: 0;
  transition-duration: 0.6s;
  transition-timing-function: ease-in-out;
  background: #fff;
}

.block.giftcard .content > form,
.gift-item-block .content > form,
.block.discount .content > form {
  padding: 1rem;
}

.block.giftcard .content[aria-hidden="false"],
.gift-item-block .content[aria-hidden="false"],
.block.discount .content[aria-hidden="false"] {
  visibility: visible;
  max-height: 450px;
}

.block.giftcard._active .content,
.gift-item-block._active .content,
.block.discount._active .content {
  visibility: visible;
  max-height: 450px;
}

.block.giftcard._active .title > span:after,
.gift-item-block._active .title > span:after,
.block.discount._active .title > span:after {
  transform: rotate(180deg);
}

.block.giftcard .actions-toolbar .action.check,
.block.giftcard .actions-toolbar .action.primary,
.block.giftcard .actions-toolbar .action.apply,
.gift-item-block .actions-toolbar .action.check,
.gift-item-block .actions-toolbar .action.primary,
.gift-item-block .actions-toolbar .action.apply,
.block.discount .actions-toolbar .action.check,
.block.discount .actions-toolbar .action.primary,
.block.discount .actions-toolbar .action.apply {
  font-size: 0.75rem;
  box-sizing: border-box;
}

.block.giftcard .actions-toolbar .action.primary,
.gift-item-block .actions-toolbar .action.primary,
.block.discount .actions-toolbar .action.primary {
  padding: 0.5rem 0.7rem;
}

.block.giftcard .actions-toolbar .action.apply,
.gift-item-block .actions-toolbar .action.apply,
.block.discount .actions-toolbar .action.apply {
  padding-top: 0;
}

.gift-item-block .title > span,
.gift-item-block .content {
  background: #fff;
}

.gift-item-block .content {
  padding: 0 1rem;
}

.gift-item-block .actions-toolbar .secondary {
  margin-top: 0.5rem;
}

@media screen and (min-width: 430px) {
  .gift-item-block .actions-toolbar .secondary {
    flex-direction: row;
    justify-content: space-between;
  }
}

.gift-item-block .actions-toolbar .secondary .action.secondary {
  flex: 0 0 auto;
  width: auto;
  margin-bottom: 0.5rem;
}

.checkout-methods-items {
  padding: 1.5rem 0 0;
  margin: 0;
}

.checkout-methods-items > .item {
  margin-bottom: 0.5rem;
  text-align: center;
}

.checkout-methods-items > .item button.checkout {
  margin: 0 auto;
}

.checkout-methods-items > .item button:not(.checkout) {
  box-sizing: border-box;
  width: auto;
  margin: 0;
}

.checkout-methods-items > .item button:not(.checkout) img {
  /* fixes size of images when button contains image - paypal button */
  max-width: 30%;
  width: auto;
  margin: 0;
}

.checkout-methods-items > .item:last-child {
  margin-bottom: 0;
}

.checkout-methods-items .action.checkout {
  max-width: 290px;
}

.totals-sticky-item {
  padding: 1rem 0.5rem;
  padding-top: 0;
  background: #fff;
  border-bottom: 1px solid #dddcdb;
  justify-content: space-between;
  margin-bottom: 1rem;
}

@media screen and (min-width: 430px) {
  .totals-sticky-item {
    padding: 1rem 0.5rem;
    flex-direction: row;
  }
}

.totals-sticky-item__total {
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}

@media screen and (min-width: 430px) {
  .totals-sticky-item__total {
    flex-direction: column;
    justify-content: flex-start;
    width: auto;
    margin-bottom: 0;
  }
}

.totals-sticky-item__action .action.primary.checkout {
  max-width: 265px;
}

.totals-sticky-item .total-sticky-item-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.totals-sticky {
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 0;
  z-index: 4;
}

@media screen and (min-width: 992px) {
  .totals-sticky {
    display: none;
  }
}

/*
 * Layout of One page checkout styles
 */
.checkout-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 1.5rem;
  justify-content: center;
}

@media screen and (min-width: 992px) {
  .checkout-container {
    justify-content: flex-start;
  }
}

.checkout-container > .opc-progress-bar,
.checkout-container > .opc-estimated-wrapper,
.checkout-container > .messages {
  width: 100%;
}

.checkout-container > .messages,
.checkout-container > .opc-wrapper {
  width: 100%;
}

@media screen and (min-width: 430px) {
  .checkout-container > .messages,
  .checkout-container > .opc-wrapper {
    width: 90%;
  }
}

@media screen and (min-width: 992px) {
  .checkout-container > .messages,
  .checkout-container > .opc-wrapper {
    width: calc(65% - 20px);
  }
}

@media screen and (min-width: 1200px) {
  .checkout-container > .messages,
  .checkout-container > .opc-wrapper {
    width: calc(100% - 460px);
  }
}

.checkout-container > .ui-effects-wrapper,
.checkout-container > .messages {
  order: 5;
}

.checkout-container > .ui-effects-wrapper {
  display: flex;
  flex-direction: column;
  width: 100% !important;
}

@media screen and (min-width: 992px) {
  .checkout-container > .opc-sidebar {
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    top: 10px;
    z-index: 1;
    background: transparent;
    width: 35%;
    margin-left: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .checkout-container > .opc-sidebar {
    width: 400px;
    margin-left: 60px;
  }
}

.checkout-container > .opc-sidebar.custom-slide .modal-inner-wrap {
  background: #eee;
}

@media screen and (min-width: 992px) {
  .checkout-container > .opc-sidebar.custom-slide .modal-inner-wrap {
    background: transparent;
    max-height: none;
    height: auto;
  }
}

.checkout-container > .opc-sidebar [data-role='focusable-start'],
.checkout-container > .opc-sidebar [data-role='focusable-end'] {
  display: none;
}

.checkout-container + .message {
  margin-top: 1.5rem;
}

.checkout-container .custom-slide .action-close {
  padding: 0.5rem;
  top: 0;
  right: 0;
}

.checkout-index-index .checkout-container > .loading-mask {
  z-index: 1120;
}

.checkout-index-index .action.nav-toggle {
  display: none;
}

@media screen and (min-width: 992px) {
  .opc-summary-wrapper .action-close {
    display: none !important;
  }
}

.opc-summary-wrapper:focus {
  outline: 0 none;
}

.opc-summary-wrapper .product-image-wrapper img {
  object-fit: contain;
}

.opc-block-summary {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}

.opc-block-summary .title {
  font-weight: 800;
  margin-bottom: 1rem;
}

.opc-block-summary .title {
  font-size: 13px;
}

@media screen and (min-width: 0px) {
  .opc-block-summary .title {
    font-size: calc(13px + 12 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .opc-block-summary .title {
    font-size: 25px;
  }
}

.opc-block-summary > * {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .opc-block-summary > * {
    padding: 1rem;
    background: #eee;
  }
}

.opc-block-summary .table-totals {
  order: 2;
  margin-top: 1rem;
  border-bottom: 1px solid #e1e1e1;
}

@media screen and (min-width: 992px) {
  .opc-block-summary .table-totals {
    margin-top: 0;
    border-left: 1rem solid #eee;
    border-right: 1rem solid #eee;
    border-bottom: 0 none;
  }
}

.opc-block-summary .table-totals td,
.opc-block-summary .table-totals th {
  padding-bottom: 1rem;
}

.opc-block-summary .table-totals th {
  font-size: 1rem;
}

.opc-block-summary .table-totals td {
  text-align: right;
}

.opc-block-summary .table-totals .totals.sub td,
.opc-block-summary .table-totals .totals.sub th {
  font-size: 0.8rem;
  font-weight: 800;
}

@media screen and (min-width: 768px) {
  .opc-block-summary .table-totals .totals.sub td,
  .opc-block-summary .table-totals .totals.sub th {
    font-size: 1rem;
  }
}

.opc-block-summary .table-totals .totals.sub td .price,
.opc-block-summary .table-totals .totals.sub th .price {
  font-size: 0.88rem;
}

@media screen and (min-width: 768px) {
  .opc-block-summary .table-totals .totals.sub td .price,
  .opc-block-summary .table-totals .totals.sub th .price {
    font-size: 1.1rem;
  }
}

.opc-block-summary .table-totals .grand.totals {
  border-top: 4px solid #000;
}

.opc-block-summary .table-totals .grand.totals td,
.opc-block-summary .table-totals .grand.totals th {
  position: relative;
  font-size: 0.8rem;
  padding-top: 1rem;
}

@media screen and (min-width: 768px) {
  .opc-block-summary .table-totals .grand.totals td,
  .opc-block-summary .table-totals .grand.totals th {
    font-size: 1rem;
  }
}

.opc-block-summary .table-totals .grand.totals td .price,
.opc-block-summary .table-totals .grand.totals th .price {
  font-size: 1.056rem;
}

@media screen and (min-width: 768px) {
  .opc-block-summary .table-totals .grand.totals td .price,
  .opc-block-summary .table-totals .grand.totals th .price {
    font-size: 1.32rem;
  }
}

.opc-block-summary .block.items-in-cart {
  order: 1;
}

.opc-block-summary .block.items-in-cart .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 1rem 0;
  font-size: 1rem;
  color: #D7D38A;
  border-bottom: 1px solid rgba(215, 211, 138, 0.4);
  margin-bottom: 0;
}

.opc-block-summary .block.items-in-cart .title:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.opc-block-summary .block.items-in-cart .title:before {
  transition: transform 0.195s cubic-bezier(0.4, 0, 0.6, 1);
  will-change: transform;
  backface-visibility: hidden;
}

.opc-block-summary .block.items-in-cart .title:focus {
  outline: 0 none;
}

.opc-block-summary .block.items-in-cart.active .title:before {
  transform-origin: 50% 50%;
  content: "";
}

.opc-block-summary .block.items-in-cart.active > .minicart-items {
  background: rgba(215, 211, 138, 0.05);
  padding: 1rem;
  border-bottom: 1px solid rgba(215, 211, 138, 0.4);
  animation: u-animation-scale-down 0.375s cubic-bezier(0, 0, 0.2, 1);
  animation-fill-mode: both;
}

.opc-block-summary .block.items-in-cart.active > .minicart-items > .minicart-items-wrapper {
  animation: u-animation-fade 0.375s cubic-bezier(0.4, 0, 0.6, 1);
  animation-delay: 0.375s;
  animation-fill-mode: both;
}

.opc-block-summary caption {
  display: none;
}

.opc-block-summary .minicart-items ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.opc-block-summary .minicart-items ol > .product-item {
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e1e1e1;
}

.opc-block-summary .minicart-items ol > .product-item:last-child {
  margin-bottom: 0;
  border-bottom: 0 none;
  padding-bottom: 0;
}

.opc-block-summary .minicart-items ol .options .toggle {
  color: #767676;
}

.opc-block-summary .minicart-items ol .options .toggle:after {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.opc-block-summary .minicart-items ol .options .toggle:after {
  position: relative;
  top: 3px;
  margin-left: 6px;
}

.opc-block-summary .minicart-items ol .options .toggle[aria-expanded='true']:after {
  content: "";
}

.opc-block-summary .minicart-items ol .options .toggle,
.opc-block-summary .minicart-items ol .options .content {
  font-size: 0.875rem;
  background: #fff;
  padding: 4px 10px;
}

.opc-block-summary .minicart-items ol .options .content {
  padding-top: 10px;
}

.opc-block-summary .minicart-items ol .options dl {
  margin-bottom: 0.5rem;
}

.opc-block-summary .minicart-items ol .product:not(.options) {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.opc-block-summary .minicart-items ol .product:not(.options) .product-image-container {
  margin-right: 0.5rem;
}

.opc-block-summary .minicart-items ol .product:not(.options) .product-item-details {
  flex: 1 1 auto;
  max-width: 100%;
}

.opc-block-summary .minicart-items ol .product:not(.options) .product-item-inner {
  display: flex;
  align-items: flex-start;
}

.opc-block-summary .minicart-items ol .product:not(.options) .product-item-inner .product-item-name-block {
  padding-right: 1rem;
  margin-bottom: 0.5rem;
  width: 60%;
}

.opc-block-summary .minicart-items ol .product:not(.options) .product-item-inner .subtotl {
  width: 40%;
  text-align: right;
}

.opc-block-summary .minicart-items ol .product:not(.options) .product-item-name {
  display: block;
  line-height: 1.3;
  margin-bottom: 1rem;
  font-weight: normal;
}

.opc-block-summary .minicart-items ol .product:not(.options) .price {
  font-weight: 800;
  font-size: 1.2rem;
}

.opc-block-summary .minicart-items ol .product:not(.options) .label {
  display: inline-block;
  color: #767676;
  margin-right: 0.25rem;
}

.opc-block-summary .minicart-items .product-image-photo {
  object-fit: contain;
}

.opc-wrapper {
  float: none;
  clear: both;
}

.opc-wrapper::after {
  display: block;
  clear: both;
  content: "";
}

.opc-wrapper ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.opc-wrapper .opc {
  list-style: none;
}

.opc-wrapper .opc > li {
  padding: 0 1rem;
}

@media screen and (min-width: 1200px) {
  .opc-wrapper .opc > li {
    padding: 0;
  }
}

.opc-wrapper .opc > li.checkout-payment-method {
  padding: 0;
}

.opc-wrapper .step-title {
  padding: 1rem;
  margin-bottom: 1rem;
  background: #eee;
  font-weight: 800;
}

.opc-wrapper .step-title {
  font-size: 13px;
}

@media screen and (min-width: 0px) {
  .opc-wrapper .step-title {
    font-size: calc(13px + 12 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .opc-wrapper .step-title {
    font-size: 25px;
  }
}

.opc-wrapper legend {
  font-weight: bold;
}

.opc-wrapper legend {
  font-size: 10px;
}

@media screen and (min-width: 0px) {
  .opc-wrapper legend {
    font-size: calc(10px + 10 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .opc-wrapper legend {
    font-size: 20px;
  }
}

@media screen and (min-width: 768px) {
  .opc-wrapper .step-content .actions-toolbar {
    justify-content: space-between;
  }
}

.opc-wrapper .step-content .primary.disableField {
  opacity: 0.3;
  pointer-events: none;
}

.opc-wrapper .step-content .message.notice {
  margin-bottom: 1rem;
}

.opc-wrapper #checkout-step-login {
  position: relative;
}

.opc-wrapper #checkout-step-login .form-login .actions-toolbar {
  margin-top: 1.5rem;
}

.opc-wrapper #checkout-step-login .form-login .secondary {
  align-items: flex-start;
  text-align: left;
}

@media screen and (min-width: 768px) {
  .opc-wrapper #checkout-step-login .actions-toolbar {
    margin: 0;
    justify-content: flex-start;
  }
}

@media screen and (min-width: 768px) {
  .opc-wrapper #checkout-step-login .passwordShown {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.opc-wrapper .note {
  color: #767676;
}

.opc-wrapper #tooltip-label {
  display: none;
}

[data-role='checkout-messages'] .message {
  margin-bottom: 1rem;
}

.opc-estimated-wrapper {
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 0;
  display: table;
  padding: 1rem;
  background: #eee;
  color: #767676;
  margin-bottom: 1rem;
  z-index: 1020;
  border-bottom: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
}

.opc-estimated-wrapper::after {
  display: block;
  clear: both;
  content: "";
}

@media screen and (min-width: 992px) {
  .opc-estimated-wrapper {
    display: none;
  }
}

.opc-estimated-wrapper .estimated-block,
.opc-estimated-wrapper .minicart-wrapper {
  display: table-cell;
  width: 50%;
  vertical-align: middle;
}

.opc-estimated-wrapper .minicart-wrapper {
  text-align: right;
}

@media screen and (min-width: 992px) {
  .opc-estimated-wrapper .minicart-wrapper {
    display: none;
  }
}

.opc-estimated-wrapper .minicart-wrapper .showcart.action {
  color: #767676;
  height: 32px;
}

.opc-estimated-wrapper .minicart-wrapper .showcart.action:before {
  font-size: 28px;
  height: 28px;
}

.opc-estimated-wrapper .minicart-wrapper .showcart.action .counter {
  top: 0px;
  left: 21px;
  background: #767676;
  color: #fff;
}

.opc-estimated-wrapper .estimated-label:after {
  content: ': ';
  margin-right: 4px;
}

.opc-estimated-wrapper .estimated-price {
  font-weight: 800;
  color: #D7D38A;
}

.opc-progress-bar {
  position: relative;
  justify-content: space-between;
  max-width: 480px;
  margin: 0 auto 1rem auto;
  align-items: stretch;
}

.opc-progress-bar > .opc-progress-bar-item {
  position: relative;
  z-index: 2;
}

.opc-progress-bar > .opc-progress-bar-item:after {
  position: absolute;
  top: 15px;
  right: auto;
  bottom: auto;
  left: 0;
  content: '';
  width: 100%;
  height: 4px;
  background: #eee;
  z-index: 1;
}

.opc-progress-bar > .opc-progress-bar-item:first-child:after, .opc-progress-bar > .opc-progress-bar-item:last-child:after {
  width: 50%;
}

.opc-progress-bar > .opc-progress-bar-item:first-child:after {
  left: auto;
  right: 0;
}

.opc-progress-bar > .opc-progress-bar-item > span {
  cursor: pointer;
}

.opc-progress-bar > .opc-progress-bar-item:only-child:after {
  display: none;
}

.opc-progress-bar > li:nth-last-child(n+3),
.opc-progress-bar li:nth-last-child(n+3) ~ li {
  max-width: 160px;
}

.opc-progress-bar-item {
  position: relative;
  width: 100%;
  align-items: stretch;
}

.opc-progress-bar-item span {
  z-index: 2;
  padding-top: 38px;
  text-align: center;
}

.opc-progress-bar-item span:before {
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  width: 30px;
  height: 30px;
  content: '';
  background: #eee;
  display: block;
  border-radius: 100%;
  border: 6px solid #fff;
  margin-bottom: 0.5rem;
  z-index: 2;
  transform: translateX(-15px);
}

.opc-progress-bar-item._active span:before {
  background: #b0aa00;
  box-shadow: 0 0 2px #b0aa00, -2px 0 2px #b0aa00, 2px 0 2px #b0aa00, 0 -2px 2px #b0aa00, 0 2px 2px #b0aa00;
}

.opc-progress-bar-item._active:after {
  background: #b0aa00;
}

.opc-progress-bar-item._active span {
  color: #b0aa00;
}

.opc-progress-bar-item._complete span:before {
  width: 30px;
  height: 30px;
  background: #b0aa00;
  content: "";
  font-family: "ms-icons";
  color: #eee;
  border: 0 none;
  box-shadow: none;
}

.opc-progress-bar-item._complete:after {
  background: #b0aa00;
}

.opc-progress-bar-item._complete span {
  color: #b0aa00;
}

.shipping-information {
  border-bottom: 1px solid #e1e1e1;
}

@media screen and (min-width: 992px) {
  .shipping-information {
    border-bottom: 0 none;
  }
}

.shipping-information .ship-to,
.shipping-information .ship-via {
  background: #eee;
  margin-bottom: 1rem;
}

@media screen and (min-width: 992px) {
  .shipping-information .ship-to,
  .shipping-information .ship-via {
    padding: 1rem;
  }
}

.shipping-information .ship-to {
  border-bottom: 1px solid #e1e1e1;
}

@media screen and (min-width: 992px) {
  .shipping-information .ship-to {
    border-bottom: 0 none;
  }
}

.shipping-information .shipping-information-title {
  font-weight: 800;
  margin-bottom: 1rem;
  background: #eee;
  justify-content: space-between;
}

.shipping-information .shipping-information-title {
  font-size: 13px;
}

@media screen and (min-width: 0px) {
  .shipping-information .shipping-information-title {
    font-size: calc(13px + 12 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .shipping-information .shipping-information-title {
    font-size: 25px;
  }
}

.shipping-information .action-edit {
  padding: 0.25rem;
}

.shipping-information .action-edit:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.table-checkout-shipping-method {
  width: 100%;
  background: #eee;
  margin-bottom: 1rem;
}

.table-checkout-shipping-method th,
.table-checkout-shipping-method td {
  padding: 0.5rem 1rem;
}

@media screen and (max-width: 768px) {
  .table-checkout-shipping-method th,
  .table-checkout-shipping-method td {
    display: block;
    text-align: center;
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .table-checkout-shipping-method th.col-method,
  .table-checkout-shipping-method th .price,
  .table-checkout-shipping-method td.col-method,
  .table-checkout-shipping-method td .price {
    font-weight: 800;
  }
}

@media screen and (max-width: 768px) {
  .table-checkout-shipping-method th.col-carrier,
  .table-checkout-shipping-method td.col-carrier {
    color: #767676;
  }
}

@media screen and (max-width: 768px) {
  .table-checkout-shipping-method thead {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .table-checkout-shipping-method tr {
    display: block;
    padding: 0.5rem;
    border-bottom: 0.5rem solid #fff;
  }
}

.table-checkout-shipping-method th {
  background: #e1e1e1;
}

@media screen and (max-width: 768px) {
  .table-checkout-shipping-method th {
    display: none;
  }
}

.table-checkout-shipping-method .store-container-inner {
  width: 100%;
}

.table-checkout-shipping-method .shipping-method-row.is-active {
  background: #e9e9e9;
}

@media screen and (min-width: 768px) {
  #shipping-method-buttons-container {
    justify-content: flex-end;
  }
}

[name="shippingAddress.custom_attributes.address_finder"] .button {
  padding: 0.5rem;
  font-weight: bold;
  text-decoration: underline;
}

.shipping-address-items {
  flex-wrap: wrap;
}

@media screen and (min-width: 992px) {
  .shipping-address-items {
    align-items: stretch;
    justify-content: flex-start;
  }
}

.shipping-address-items .shipping-address-item {
  position: relative;
  width: 200px;
  background: #eee;
  padding: 1rem;
  padding-right: 40px;
  margin: 0.5rem;
  max-width: 100%;
}

.shipping-address-items .shipping-address-item:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.shipping-address-items .shipping-address-item:before {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem;
  border: 1px solid #fff;
  transform: translateY(90%);
  opacity: 0;
}

.shipping-address-items .shipping-address-item .edit-address-link {
  box-sizing: border-box;
  font-weight: 800;
  text-decoration: underline;
  padding: 1rem 0;
}

.shipping-address-items .shipping-address-item.selected-item {
  background: #b0aa00;
  color: #fff;
}

.shipping-address-items .shipping-address-item.selected-item a {
  color: #fff;
}

.shipping-address-items .shipping-address-item.selected-item:before {
  transform: translateY(0%);
  opacity: 1;
}

.shipping-address-items .shipping-address-item.selected-item .action-select-shipping-item {
  opacity: 0;
  visibility: hidden;
}

.shipping-address-items .action-select-shipping-item {
  margin: 1rem auto 0;
  font-size: 75%;
}

#checkout-step-shipping .action-show-popup {
  margin: 0.75rem auto 1.5rem auto;
}

#checkout-step-shipping .action-show-popup:before {
  content: '+ ';
  display: inline-block;
  margin-right: 8px;
}

input[data-placeholder="null"]::placeholder {
  color: white;
  opacity: 0;
}

input[data-placeholder="null"]::-webkit-input-placeholder {
  color: white;
  opacity: 0;
}

input[data-placeholder="null"]::-moz-placeholder {
  color: white;
  opacity: 0;
}

input[data-placeholder="null"]:-ms-input-placeholder {
  color: white;
  opacity: 0;
}

.checkout-shipping-address .field[name="shippingAddress.street.0"] label, .checkout-shipping-address .field[name="shippingAddress.street.1"] label, .checkout-shipping-address .field[name="shippingAddress.street.2"] label, .checkout-shipping-address .field[name="shippingAddress.street.3"] label, .checkout-shipping-address .field[name="shippingAddress.street.4"] label, .checkout-shipping-address .field[name="shippingAddress.street.5"] label, .checkout-shipping-address .field[name="shippingAddress.street.6"] label {
  display: none;
}

.payment-method {
  padding: 1.5rem 0.25rem;
  border-bottom: 1px solid #e1e1e1;
  opacity: 0.8;
}

@media screen and (min-width: 992px) {
  .payment-method {
    padding: 1.5rem 1rem;
  }
}

.payment-method .payment-method-title {
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-bottom: 0;
}

.payment-method .payment-method-title .radio {
  margin-right: 0.5rem;
}

.payment-method .payment-method-title .label {
  position: relative;
  margin-bottom: 0;
  font-weight: 800;
  font-size: 1rem;
  flex: 1 1 auto;
  justify-content: flex-start;
}

@media screen and (min-width: 992px) {
  .payment-method .payment-method-title .label {
    font-size: 1.2rem;
  }
}

.payment-method .payment-method-title .label > span {
  padding-left: 1rem;
}

.payment-method .payment-method-title .label .action-help {
  position: absolute;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 0.875rem;
  color: #767676;
  transform: translateY(100%);
  padding-top: 0.25rem;
  text-align: left;
}

.payment-method .payment-method-title .label[for='paypal_express'] {
  margin-bottom: 1rem;
}

.payment-method .payment-method-content {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-50px);
  transform-origin: 0% 0%;
  height: 0;
  overflow: hidden;
}

.payment-method .payment-method-content > * {
  transition-duration: 0.195s;
  transition-delay: 0.11s;
  opacity: 0;
}

.payment-method .payment-method-content .actions-toolbar {
  justify-content: flex-end;
}

.payment-method .payment-method-content .billing-address-details {
  line-height: 1.3;
}

.payment-method .payment-method-note {
  background: #def5ff;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: flex-start;
  display: flex !important;
}

.payment-method .payment-method-note:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

@media screen and (min-width: 992px) {
  .payment-method .payment-method-note {
    margin-top: 0;
    justify-content: flex-start;
  }
}

.payment-method .payment-method-note:before {
  margin-right: 0.5rem;
}

.payment-method._active {
  background: #eee;
  opacity: 1;
}

.payment-method._active .payment-method-title {
  margin-bottom: 1.5rem;
}

.payment-method._active .payment-method-content {
  height: auto;
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}

.payment-method._active .payment-method-content > * {
  opacity: 1;
}

@media screen and (max-width: 992px) {
  .payment-method .action,
  .payment-method .button-base {
    box-sizing: border-box;
    width: 100%;
    max-width: none;
  }
}

.checkout-billing-address .actions-toolbar .primary {
  width: 100%;
  justify-content: space-around;
  margin-bottom: 0;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .checkout-billing-address .actions-toolbar .primary .action {
    font-size: 11px;
    padding: 0.5rem 1.5rem;
  }
}

@media screen and (max-width: 992px) {
  .checkout-billing-address .actions-toolbar .primary .action {
    margin-bottom: 1rem;
  }
}

.checkout-billing-address .action-edit-address {
  box-sizing: border-box;
  font-weight: 800;
  text-decoration: underline;
  padding: 1rem 0;
}

.payment-group,
.payment-method-billing-address {
  margin-bottom: 1rem;
}

.billing-address-same-as-shipping-block + .billing-address-details + .fieldset {
  padding: 0.5rem;
  background: #f3f3f3;
}

.payment-option._collapsible {
  background: #eee;
  border: 1px solid #e1e1e1;
  margin-bottom: 1rem;
  padding: 0.5rem;
}

@media screen and (min-width: 768px) {
  .payment-option._collapsible {
    float: left;
    width: 50%;
  }
}

.payment-option._collapsible._active {
  background: #eee;
}

.payment-option._collapsible .action-toggle {
  width: 100%;
  max-width: none;
}

.payment-option._collapsible .actions-toolbar .secondary {
  flex: 0 0 auto;
}

.payment-option._collapsible .actions-toolbar .action {
  padding: 0.5rem 1rem;
}

.payment-option._collapsible .actions-toolbar .action.action-add, .payment-option._collapsible .actions-toolbar .action.action-apply {
  color: #000;
  border-color: #000;
}

.payment-option .payment-option-title {
  font-weight: bold;
  margin-bottom: 0;
}

.form.payments .fieldset::after {
  display: block;
  clear: both;
  content: "";
}

#sagepaysuiteserver-actions-toolbar {
  display: flex !important;
  margin-bottom: 0.5rem;
}

#sagepaysuiteserver-payment-errors {
  padding-left: 70px;
  position: relative;
  margin-bottom: 0.5rem;
}

#sagepaysuiteserver-payment-errors:before {
  display: block;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
}

.payment-group .payment-method {
  padding: 1rem;
}

.checkout-onepage-success .column.main {
  margin-top: 1.5rem;
}

.checkout-success-block {
  background: #eee;
  padding: 1rem;
  max-width: 700px;
  width: 100%;
  margin: 0 auto 0.5rem auto;
}

@media screen and (min-width: 768px) {
  .checkout-success-block {
    padding: 1.5rem;
  }
}

.checkout-success-block h1 {
  font-size: 13px;
}

@media screen and (min-width: 0px) {
  .checkout-success-block h1 {
    font-size: calc(13px + 12 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .checkout-success-block h1 {
    font-size: 25px;
  }
}

.checkout-success-block p {
  max-width: 75ch;
}

.checkout-success-block .order-number {
  display: block;
  padding: 1rem 0;
  font-size: 1.25rem;
  font-weight: 800;
  color: #D7D38A;
  text-align: center;
}

@media screen and (min-width: 430px) {
  .checkout-success-block .order-number {
    text-align: left;
  }
}

.checkout-success-block .actions-toolbar > .secondary,
.checkout-success-block #mailchimp-groups .primary {
  margin-bottom: 0;
}

@media screen and (max-width: 430px) {
  .checkout-success-block .action {
    width: 100%;
    max-width: none;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .checkout-success-block .action {
    max-width: 500px;
  }
}

.checkout-success-block .order-email {
  font-weight: 800;
}

/* fix payment method issues when company credit is enabled */
body.checkout-index-index .payment-method .available-credit {
  display: flex;
  width: 100%;
}

body.checkout-index-index .payment-method .payment-method-content {
  width: 100%;
}

body.checkout-index-index .payment-method .payment-method-title {
  flex-wrap: wrap;
}

body.checkout-index-index .payment-method .amcompany-paycard-block.-balance-card {
  background-color: #fff;
  border-color: #fff;
}

/* override label targetted globally - fixes float label issues */
body .payment-method .payment-method-title .checkout-billing-address .label > span {
  padding-left: 0;
}

.payment-method .payment-method-title .label {
  font-weight: normal;
  text-transform: uppercase;
}

.payment-method .payment-method-title .available-credit .label {
  text-transform: none;
}

.payment-method .payment-method-title .available-credit {
  margin-bottom: 1rem;
}

.payment-method .payment-method-title .available-credit .radio {
  margin-bottom: 0;
}

body .payment-method .payment-method-title .label.fl-label {
  font-size: 12px;
  position: absolute;
  font-weight: 800;
  margin-bottom: .5rem;
}

body .form-billing-address-form .choice.field .label {
  font-size: 1rem;
  text-transform: none;
  font-weight: normal;
}

body .table-checkout-shipping-method tbody tr td.col-method .field {
  margin-bottom: 0;
}

body .table-checkout-shipping-method tbody tr td.col-method input[type="radio"] {
  margin-top: 0;
  margin-bottom: 0;
  height: 26px;
}

body .checkout-container .form.methods-shipping select,
body .checkout-container .form.methods-shipping input {
  border-width: 2px;
}

.opc-block-summary .block.items-in-cart .title {
  color: #000;
}

@media screen and (min-width: 992px) {
  body .checkout-container > .opc-sidebar {
    top: 40px;
  }
}

body .checkout-container .opc-block-summary {
  padding-top: 0;
}

body .checkout-container .opc-estimated-wrapper {
  top: 44px;
}

body .opc-progress-bar-item._active span {
  color: #b0aa00;
}

body .payment-method .payment-method-title .radio {
  margin-bottom: 0;
}

body .payment-method .payment-method-title .label {
  text-transform: none;
  font-weight: bold;
}

body .price-excluding-tax:before,
body .price-including-tax:before {
  top: 0;
}

body .payment-option._collapsible .action-toggle {
  position: relative;
}

body .payment-option._collapsible .action-toggle:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

body .payment-option._collapsible .action-toggle:before {
  position: absolute;
  top: 8px;
  right: 0;
}

body .payment-option._collapsible._active .action-toggle:before {
  content: "";
}

body .payment-option._collapsible .payment-option-content {
  padding: 1rem 0 0;
}

body .payment-option._collapsible .actions-toolbar {
  justify-content: center;
}

#cc_c2a.c2a_mode1 {
  z-index: 9999;
}

body .opc-wrapper .step-title,
body .opc-block-summary > .title {
  background: #000;
  border: 2px solid #000;
  color: #fff;
}

body .shipping-address-items .shipping-address-item {
  border: 2px solid #ccc;
  background-color: #efefef;
  color: #000;
  width: 100%;
}

body .shipping-address-items .shipping-address-item:before {
  border: 1px solid #000;
}

@media screen and (min-width: 430px) {
  body .shipping-address-items .shipping-address-item {
    width: 256px;
  }
}

@media screen and (max-width: 430px) {
  body .opc-wrapper .opc > li {
    padding-left: 0;
    padding-right: 0;
  }
}

body .shipping-address-items .shipping-address-item.selected-item {
  border: 2px solid #ccc;
  background-color: #efefef;
  color: #000;
}

body .shipping-address-items .shipping-address-item.selected-item:before {
  border: 1px solid #000;
}

body .opc-block-summary .block.items-in-cart {
  background-color: #efefef;
}

body .opc-block-summary .block.items-in-cart.active > .minicart-items {
  background-color: white;
}

#co-payment-form hr {
  display: none;
}

body .payment-method._active {
  background-color: #efefef;
}

body .payment-option._collapsible {
  background: #efefef;
  border: 2px solid #ccc;
  float: none;
  width: 100%;
}

body .opc-block-summary .table-totals .grand.totals {
  border-width: 2px;
  border-color: #ccc;
}

body.checkout-cart-index .cart-summary .table .grand.totals {
  border-top: 2px solid #ccc;
  font-size: 1rem;
}

body.checkout-cart-index .cart-summary .table .grand.totals th {
  color: inherit;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

body.checkout-cart-index .cart-summary .table .grand.totals td.amount .price {
  font-size: 1.32rem;
}

body.checkout-cart-index .cart-summary .cart-totals th {
  padding-bottom: 1rem;
}

body.checkout-cart-index .cart-summary .table .sub.totals {
  font-size: 1rem;
  font-weight: bold;
}

body.checkout-cart-index .cart-summary .table .sub.totals th {
  font-weight: bold;
  color: inherit;
}

body.checkout-cart-index .cart-summary .table .sub.totals td .price {
  font-size: 1.1rem;
}

body .item-options dd.values,
body .cart-container-main .cart.table .item-options dd,
body .cart-container-main .cart.table .item-options dt {
  display: inline-block;
  margin: 0;
  font-size: .875rem;
  line-height: normal;
}

body .opc-block-summary .minicart-items ol .options .content,
body .opc-block-summary .minicart-items ol .options .toggle {
  padding-left: 0;
  padding-right: 0;
}

/* basket page summary box background */
body .cart-container .cart-container-sidebar .cart-summary {
  background: #efefef;
  border: 2px solid #ccc;
}

body .cart-container-main .cart.table .cart.item:not(.message) {
  border: 2px solid #ccc;
}

@media screen and (max-width: 992px) {
  body .opc-estimated-wrapper .estimated-block,
  body .opc-estimated-wrapper .minicart-wrapper {
    width: unset;
    color: #000;
  }
}

body .opc-estimated-wrapper .minicart-wrapper .showcart.action,
body .opc-estimated-wrapper .estimated-price {
  color: #000;
}

body .amcompany-paycard-block {
  margin-top: 20px;
}

body.checkout-index-index .ntrn__toggle {
  display: none;
}

body.checkout-index-index.page-layout-1column .page-main {
  margin-top: 0;
}

@media screen and (max-width: 991.98px) {
  body .checkout-container .modal-custom.opc-sidebar .opc-block-summary {
    padding-top: 20px;
  }
  body .checkout-container .modal-custom.opc-sidebar .opc-block-summary > .title {
    display: none;
  }
}

body .table-checkout-shipping-method td {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

@media screen and (max-width: 991.98px) {
  body .table-checkout-shipping-method tbody tr td.col-method input[type="radio"] {
    margin: 0;
  }
}

body .cart-container {
  margin-bottom: 3rem;
}

/* basket page rental system form element spacing */
.cart-container-sidebar .form.form-rental-period .fieldset {
  margin-bottom: 1rem;
}

@media screen and (min-width: 992px) {
  body .cart-container .cart-container-sidebar {
    width: calc(100% - 570px - 18px);
    max-width: 450px;
  }
}

/* hide print button from checkout success page LGH-488 */
body .checkout-success .actions-toolbar {
  margin-top: 0;
  margin-bottom: 0;
}

body .checkout-success .actions-toolbar .primary {
  margin-bottom: 0;
}

body .checkout-success .actions-toolbar .secondary .action.print {
  display: none;
}

body .checkout-success-block {
  margin-bottom: 1.5rem;
}

/* remove the billing address tick box and address on billing step checkout LGH-497 */
body .checkout-payment-method .checkout-billing-address {
  display: none;
}
