.totals-sticky-item {
  @extend %u-flex-col-xcenter-ycenter;
  padding: spacers(3) spacers(2);
  padding-top: 0;
  background: $totals-sticky-item-bg;
  border-bottom: $totals-sticky-item-border;
  justify-content: space-between;
  margin-bottom: $basket-page-spacing-y;

  @include respond-to-up(smm) {
    padding: spacers(3) spacers(2);
    flex-direction: row;
  }

  &__total {
    @extend %u-flex-row-xcenter-ycenter;
    justify-content: space-between;
    width: 100%;
    margin-bottom: $basket-page-spacing-y;

    @include respond-to-up(smm) {
      flex-direction: column;
      justify-content: flex-start;
      width: auto;
      margin-bottom: 0;
    }
  }

  &__action .action.primary.checkout {
    max-width: 265px;
  }

  .total-sticky-item-title {
    font-size: $font-size-lg;
    font-weight: bold;
  }
}

.totals-sticky {
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 0;
  z-index: z('above') + 2;

  @include respond-to-up($page-layout-breakpoint) {
    display: none;
  }
}