// opc-wrapper
.opc-wrapper {
  @include clearfix;
  float: none;
  clear: both;

  ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .opc {
    list-style: none;

    > li {
      padding: 0 $container-padding;

      @include respond-to-up(xl) {
        padding: 0;
      }

      &.checkout-payment-method {
        padding: 0;
      }
    }
  }

  .step-title {
    @include fluid-type($opc-step-title-fluid-size);
    padding: $opc-step-title-padding;
    margin-bottom: $opc-step-title-margin-bottom;
    background: $opc-step-title-bg;
    font-weight: $font-weight-bold;
  }

  legend {
    @include fluid-type(h5);
    font-weight: bold;
  }

  .step-content {
    .actions-toolbar {
      @include respond-to-up(md) {
        justify-content: space-between;
      }
    }

    .primary {
      &.disableField {
        opacity: 0.3;
        pointer-events: none;
      }
    }

    .message.notice {
      margin-bottom: spacers(3);
    }
  }

  #checkout-step-login {
    position: relative;

    .form-login {
      .actions-toolbar {
        margin-top: spacers(4);
      }
      .secondary {
        align-items: flex-start;
        text-align: left;
      }
    }
    .actions-toolbar {
      @include respond-to-up(md) {
        margin: 0;
        justify-content: flex-start;
      }
    }
    .passwordShown {
      @include respond-to-up(md) {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }

  .note {
    color: $text-muted;
  }

  #tooltip-label {
    display: none;
  }
}

[data-role='checkout-messages'] .message {
  margin-bottom: spacers(3);
}
