.checkout-onepage-success .column.main {
  margin-top: spacers(4);
}

.checkout-success-block {
  background: $checkout-success-bg;
  padding: $checkout-success-padding-sm;
  max-width: $checkout-success-max-width;
  width: 100%;
  margin: 0 auto $checkout-success-spacing-y auto;

  @include respond-to-up(md) {
    padding: $checkout-success-padding-md;
  }

  h1 {
    @include fluid-type(h4);
  }

  p {
    max-width: 75ch;
  }

  .order-number {
    display: block;
    padding: spacers(3) 0;
    font-size: $checkout-success-order-number-size;
    font-weight: $font-weight-bold;
    color: $checkout-success-order-number-color;
    text-align: center;

    @include respond-to-up(smm) {
      text-align: left;
    }
  }

  .actions-toolbar > .secondary,
  #mailchimp-groups .primary {
    margin-bottom: 0;
  }

  .action {
    @include respond-to-down(smm) {
      width: 100%;
      max-width: none;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    @include respond-to-down(md) {
      max-width: 500px;
    }
  }

  .order-email {
    font-weight: $font-weight-bold;
  }
}